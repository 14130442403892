import { createWebHistory, createRouter } from 'vue-router'
import Home from '../views/home-scr.vue'
import About from '../views/about-scr.vue'
import Services from '../views/services-scr.vue'
import Projects from '../views/projects-scr.vue'
import Contact from '../views/contact-scr.vue'
import Blog from '../views/blog-scr.vue'
import Legal from '../views/legal-scr.vue'
import PageNotFound from '../views/page-not-found-scr.vue'
import Blog1 from '../views/blogs/blog-1.vue'
import Blog2 from '../views/blogs/blog-2.vue'

const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home,
        meta: {
            title: 'Home'
        }
    },

    {
        name: 'About',
        path: '/about-us',
        component: About,
        meta: {
            title: 'About'
        }
    },

    {
        name: 'Services',
        path: '/services',
        component: Services,
        meta: {
            title: 'Services'
        }
    },

    {
        name: 'Project',
        path: '/projects',
        component: Projects,
        meta: {
            title: 'Project'
        }
    },

    {
        name: 'Contact',
        path: '/contact-us',
        component: Contact,
        meta: {
            title: 'Contact'
        }
    },

    {
        name: 'Blog',
        path: '/blog',
        component: Blog,
        meta: {
            title: 'Blog'
        }
    },

    {
        name: 'Legal',
        path: '/legal',
        component: Legal,
        meta: {
            title: 'Legal'
        }
    },

    {
        name: 'Blog1',
        path: '/blog/b1-revolutionizing-logistics-with-nuwarex',
        component: Blog1,
        meta: {
            title: 'Legal'
        }
    },

    {
        name: 'Blog2',
        path: '/blog/b2-the-green-mile',
        component: Blog2,
        meta: {
            title: 'Legal'
        }
    },

    {
        name: 'NotFound',
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
        meta: {
            title: '404 Not Found'
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
})

export default router;