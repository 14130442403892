<template>
    <div class="w-full min-h-screen bg-nuwarex-bg">
        <Navigation />
        <div class="h-[120px]"></div>
        <div class="container mx-auto flex flex-col lg:flex-row">
            <div class="block relative lg:hidden">
                <button class="w-full pb-3 pl-2 text-left border-b border-slate-500 flex justify-between items-center"
                    @click="topicsDropdown = !topicsDropdown">
                    <p>Legal</p>
                    <font-awesome-icon class="w-4 h-4 transition-all duration-300" :class="topicsDropdown === true ? 'rotate-[-180deg]' : 'rotate-0'" :icon="['fa', 'sort-desc']" />
                </button>
                <transition name="dropdown-trans">
                    <div v-if="topicsDropdown" class="w-full h-[246px] absolute bg-white overflow-hidden">
                        <div v-for="(item, index) in topics" :key="index" @click="topicSelect(index)">
                            <button class="w-full py-3 pl-2 text-left">{{ item.name }}</button>
                            <div class="w-full h-[1px] bg-nuwarex-bg"></div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="w-[300px] mr-5 flex-shrink-0 hidden lg:block">
                <p class="mb-3 text-[30px] leading-6">Legal</p>
                <div class="w-full h-[1px] mb-5 bg-black"></div>
                <button v-for="(item, index) in topics" :key="index"
                    class="w-full py-2 text-left text-slate-700 transition-all duration-200 hover:text-black"
                    :class="item.visible === true ? 'font-bold' : ''" @click="topicSelect(index)"
                    v-html="item.name"></button>
            </div>
            <div v-if="topics[0].visible === true" class="legal mb-16 mt-10 lg:mt-0">
                <p>
                    <span class="text-lg font-bold">Disclaimer</span> <br><br>
                    The following are the terms and conditions for use of the Nuwarex Logistics Web Portal: <br><br>
                    01. Access Terms <br>
                    Your access to the materials on this website signifies your acknowledgment and acceptance of the
                    following terms and your agreement to abide by them. The information and materials, including terms and
                    conditions, presented on our website are subject to change, removal, or amendment at any time at the
                    sole discretion of Nuwarex Logistics. <br>
                    Nuwarex Logistics does not guarantee that the website will be available continuously and will be free
                    from errors, interruptions, or free from malicious code such as viruses. Nuwarex Logistics shall not be
                    liable for any losses or damages of any kind, arising indirectly or directly from the use of this
                    website, including any inaccuracies, delays, or errors in information or in its transmission. <br><br>
                    02.Site Accuracy <br>
                    While we strive for accuracy, inadvertent errors or inaccuracies may occasionally be present on our web
                    pages. Corrections will be made as discovered at the discretion of Nuwarex Logistics. The information is
                    routinely updated, yet discrepancies may arise between updates. We disclaim any responsibility for the
                    content’s accuracy post-update. <br><br>
                    03.Copyright <br>
                    The copyright of all material presented on this site belongs to Nuwarex Logistics. <br><br>
                    4.No Warranties Provided <br>
                    The services, content, and information on this website are provided "as is" without any warranty of any
                    kind. Nuwarex Logistics expressly disclaims all warranties, either expressed or implied, including but
                    not limited to implied warranties of merchantability, suitability for a specific purpose, and
                    non-infringement. <br>
                    Nuwarex Logistics and its related entities do not warrant the completeness, accuracy, security, or
                    timeliness of the services, content, or information provided on or through the website. No information
                    obtained from the website constitutes a warranty not explicitly stated in these terms. <br><br>
                    05.Limitation of Liability <br>
                    In accordance with the law, Nuwarex Logistics, including its related entities, will not be responsible
                    for any indirect, special, incidental, punitive, or consequential damages, or any loss of profits, data,
                    or business disruption arising from the use or inability to use the website, services, content, or
                    information based on warranty, contract, tort, or any other legal theory, even if Nuwarex Logistics has
                    been advised of the possibility of such damages. <br><br>
                    You agree to defend, indemnify, and hold harmless Nuwarex Logistics and its affiliates from any claims,
                    liabilities, costs, and expenses, including legal fees, arising in any way from your use of the website
                    or your violation of these terms.
                </p>
            </div>
            <div v-if="topics[1].visible === true" class="t&c mb-16 mt-10 lg:mt-0">
                <p>
                    <span class="text-lg font-bold">Terms & Conditions</span> <br><br>
                    Welcome to nuwarex.lk <br><br>
                    Please read these terms and conditions ("T&Cs") thoroughly before using this Site and the services
                    offered by Nuwarex (Pvt) Ltd (“Nuwarex”). These T&Cs establish the legally binding terms for your use of
                    this Site and all services provided on it. <br><br>
                    Your use of this Site, including but not limited to visiting or browsing, signifies your agreement to
                    these T&Cs and any additional terms, conditions, and policies referenced herein and/or accessible via
                    hyperlink. <br><br>
                    These T&Cs govern your access to this Site and should be read in conjunction with the specific terms and
                    conditions applicable to any transaction(s), products, and/or services offered. Should there be a
                    conflict, the specific terms and conditions for the transaction(s), products, and/or services will take
                    precedence. <br><br>
                    Nuwarex reserves the right to alter these T&Cs without prior notice. <br><br>
                    1. The Site offers products and services governed by applicable terms and conditions—review these when
                    considering a purchase. <br><br>
                    2. Information on this Site may change without notice at Nuwarex's discretion. Continued use following
                    changes indicates acceptance of the new terms. <br><br>
                    3. These T&Cs are governed by the laws of Sri Lanka, and the courts of Sri Lanka will have exclusive
                    jurisdiction, except as stated in Section 1.4. <br><br>
                    4. These T&Cs do not create a partnership, agency, or employer-employee relationship between you and
                    Nuwarex. <br><br>
                    5. Inconsistencies between different language versions of these T&Cs will default to the English
                    version. <br><br>
                    You must be at least 18 years old to use this Site, and all account information must be accurate and
                    kept current, including a valid email and address. You are responsible for the confidentiality of your
                    password and any activities that occur under your account. Nuwarex is not liable for losses due to
                    unauthorized account use. <br><br>
                    Orders are subject to the T&Cs in effect at the time of the order. It is your responsibility to review
                    the current T&Cs each time you place an order. <br>
                    Prices in Sri Lankan Rupees are exclusive of delivery charges and taxes, which you are responsible for.
                    Prices include any applicable taxes. <br>
                    Delivery charges are additional and vary by weight and destination. Delivery dates are estimates, and
                    delays do not entitle customers to cancel orders. <br><br>
                    Payments can be made through specified methods. Orders are processed after confirmation of payment.
                    <br><br>
                    Product descriptions are provided by merchants, and Nuwarex makes no warranty as to their accuracy.
                    <br><br>
                    For faulty products, contact Nuwarex for verification, return the product, and upon confirmation, a
                    replacement or refund may be processed. <br><br>
                    Cancellation is possible before dispatch; otherwise, follow the Return and Refund Policy. <br><br>
                    For returns or dissatisfaction, refer to the Return and Refund Policy outlined on our Site. <br><br>
                    Privacy concerns are addressed in our Privacy Policy, which is part of these T&Cs. <br><br>
                    Nuwarex disclaims all warranties for information accuracy and is not liable for any damages resulting
                    from Site use. <br><br>
                    Nuwarex’s liability is capped at the amount of delivery charges paid. <br><br>
                    By using this Site, you agree to indemnify Nuwarex against all claims arising from your use of the Site.
                    <br><br>
                    Nuwarex does not guarantee uninterrupted access to this Site. <br><br>
                    You must comply with all laws in connection with your use of this Site. <br><br>
                    If any part of these T&Cs is found invalid, the rest remain enforceable. <br><br>
                    Complaints can be directed to Nuwarex’s customer service channels for prompt attention. <br><br>
                    Nuwarex is not responsible for delays or failures beyond its control, like natural disasters or
                    government actions. <br><br>
                    These T&Cs do not grant rights to any third parties. <br><br>
                    Content on this Site is copyrighted, and use is limited to personal, non-commercial purposes unless
                    written consent is given for other uses. <br><br>
                    Nuwarex’s trademarks may not be used without prior written consent. <br><br>
                    Copyright infringement will result in termination of Site access and further action as necessary.
                    <br><br>
                    These revised terms and conditions are tailored to fit Nuwarex, maintaining the structure and intent of
                    the original while aligning with the legal and operational frameworks relevant to Sri Lanka.
                </p>
            </div>
            <div v-if="topics[2].visible === true" class="privacy-policy mb-16 mt-10 lg:mt-0">
                <p>
                    <span class="text-lg font-bold">Privacy Policy</span> <br><br>
                    At Nuwarex, we adhere to the Sri Lankan Data Protection Act and are committed to protecting your
                    privacy. Our Personal Data Protection Notice explains our practices regarding the use of your personal
                    data. <br><br>
                    We process your personal data to deliver the logistical services you request, aiming to enhance and
                    personalize our offerings. Your data is under your control, with transparency over its usage and who has
                    access. We do not engage in direct marketing without your consent and implement robust security measures
                    to protect your data. <br><br>
                    This notice generally covers all services provided by Nuwarex. Specific transactions may have different
                    data protection notices, which we will communicate as necessary. Please review this notice carefully
                    before providing us with your personal data; using our services implies acceptance of these terms.
                    <br><br>
                    Personal data from third parties should only be shared with Nuwarex after obtaining their consent, and
                    they should be informed about this notice. <br><br>
                    We collect your data directly from interactions with you, through our services and website, and
                    occasionally from third parties and corporate entities, ensuring that consent has been obtained where
                    necessary. <br><br>
                    The types of personal data we collect include identification, contact, employment, and payment details,
                    among others. This data helps us provide and improve our services, verify identities, tailor user
                    experiences, and fulfill legal and regulatory requirements. <br><br>
                    Your personal data may be shared within Nuwarex's group of companies or with trusted third parties for
                    service delivery, legal compliance, or in the event of business transfers. <br><br>
                    We store and process data primarily in Sri Lanka but may transfer it internationally as required, with
                    the assurance of secure treatment as per this notice. <br><br>
                    The retention period for your personal data is based on the purpose for which it was collected and legal
                    obligations. Your rights include accessing and correcting your data, with specified fees for access
                    requests. <br><br>
                    You have the option to opt-out of marketing communications and limit the processing of your data through
                    various methods provided in this notice. <br><br>
                    Please note, withdrawing consent for marketing may take up to 21 days to take effect. <br><br>
                    For website interactions, be aware that cookies are used to enhance user experience, and you have the
                    option to disable them. However, no electronic transmission is entirely secure. <br><br>
                    Our notice may be updated periodically and will be communicated via our website or other suitable means.
                    <br><br>
                    For inquiries or updates to your personal data, please contact Nuwarex's. <br><br>
                    This Personal Data Protection Notice is effective as of 11/9/2023. <br><br>
                </p>
            </div>
            <div v-if="topics[3].visible === true" class="w-full imp-1 mb-16 mt-10 lg:mt-0 bg-black">
                <iframe class="w-full h-screen" src="control-act.pdf" />
            </div>
            <div v-if="topics[4].visible === true" class="w-full imp-1 mb-16 mt-10 lg:mt-0">
                <iframe class="w-full h-screen" src="control-reg.pdf" width="800px" height="2100px" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Navigation from "../components/common/navigation-com.vue";
import { useHead } from '@vueuse/head';

export default {
    components: {
        Navigation,
    },

    setup() {
        let topics = ref([
            { name: 'Disclaimer', visible: true },
            { name: 'Terms & Conditions', visible: false },
            { name: 'Privacy Policy', visible: false },
            { name: 'Import & Exports Controls Act.<br> No. 1 of 1969', visible: false },
            { name: 'Import & Exports Controls<br> Regulation Act. No. 1 of 2022', visible: false },
        ]);

        let selectedTopic = ref('Legal');
        let topicsDropdown = ref(false);

        const topicSelect = (index) => {
            topics.value = topics.value.map(item => ({ ...item, visible: false }));
            topics.value[index].visible = true;
            selectedTopic.value = topics.value[index].name;
            topicsDropdown.value = false;
        }

        onMounted(() => {
            //meta-data
            useHead({
                title: `LEGAL | NUWAREX`,
                meta: [
                    {
                        name: 'description',
                        content: `Discover reliable logistics solutions with NuWarex. Explore our Terms & Conditions for a seamless and transparent experience. Your trusted partner in efficient supply chain management.`
                    },
                ],
            });
        });

        return {
            topics,
            selectedTopic,
            topicsDropdown,
            topicSelect,
        }
    }
}
</script>
<style scoped>
.dropdown-trans-enter-from,
.dropdown-trans-leave-to {
    height: 0;
}

.dropdown-trans-enter-active,
.dropdown-trans-leave-active {
    transition: all .3s ease;
}
</style>