<template>
    <div>
        <Navigation />
        <div class="w-full min-h-screen pt-[100px] bg-nuwarex-bg">
            <div class="container mx-auto">
                <div class="flex justify-between flex-col lg:flex-row">
                    <div class="w-full">
                        <h1 class="mb-5 font-bold text-[20px] sm:text-[30px] md:text-[35px] lg:text-[50px] lg:leading-[60px]">Revolutionizing Logistics with Nuwarex: Your Gateway to Smarter, Faster, and More Reliable Supply Chain Solutions</h1>
                        <p class="mb-5 font-bold text-slate-400"></p>
                        <p class="mb-5 font-bold text-slate-400">by <span class="text-black">NUWAREX</span></p>
                    </div>
                    <div class="w-full mb-5 flex-shrink-0 sm:w-[500px] lg:ml-5 xl:w-[600px]">
                        <img class="w-full" src="../../assets/images/blog/post-1-thumb.webp" alt="post-1-thumbnail">
                    </div>
                </div>
                <p>In the fast-paced world of commerce, a logistics company that can deliver supply chain logistics solutions efficiently holds the key to success. Nuwarex stands out as that key, redefining the norms with its advanced freight forwarding services, air cargo logistics services, and third-party logistics. By integrating innovative practices with steadfast reliability, Nuwarex is the transformative force your business needs to excel in the global market.</p>
                <div class="w-full mt-5 mb-5">
                    <img class="w-full" src="../../assets/images/blog/post-1-img.webp" alt="">
                </div>
                <p>
                    <span class="font-bold">Nuwarex: The Epitome of Supply Chain Excellence</span> <br><br>
                    At Nuwarex, we understand the intricacies of supply chain logistics solutions. Our mission is to streamline your operations, making them as seamless as possible. We achieve this through a comprehensive approach that includes state-of-the-art freight forwarding services designed to enhance your transportation capabilities, whether by land, sea, or air. <br><br><br>
                    <span class="font-bold">Tailored Freight Forwarding Services</span> <br><br>
                    Our freight forwarding services are not just about moving goods; they're about moving your business forward. With a deep understanding of the nuances in transportation, we ensure that your cargo reaches its destination through the most efficient routes, avoiding delays and reducing costs. <br><br><br>
                    <span class="font-bold">Air Cargo Logistics Services: Speed and Efficiency in the Skies</span> <br><br>
                    In the era where time is currency, our air cargo logistics services offer the swiftness your business demands. We prioritize your urgent shipments, delivering them with the utmost speed and care. With Nuwarex, air freight is synonymous with reliability. <br><br><br>
                    <span class="font-bold">Third-Party Logistics: Your Strategic Ally</span> <br><br>
                    Partnering with Nuwarex for third-party logistics means you have an ally dedicated to refining your supply chain from end to end. We handle the complexities of logistics so that you can focus on what you do best: growing your business. <br><br><br>
                    <span class="font-bold">A Technology-Driven Logistics Company</span> <br><br>
                    Technology is at the heart of what we do. From real-time tracking systems to AI-driven logistics planning, Nuwarex employs cutting-edge tech to keep you ahead in the game. We believe that smart solutions are the best solutions, and our technology reflects this belief. <br><br><br>
                    <span class="font-bold">Nuwarex: Redefining Reliability</span> <br><br>
                    Reliability is not just a word for us; it's a promise. Nuwarex is committed to ensuring that your goods are not only delivered on time but also handled with the highest level of professionalism and care throughout the journey. <br><br><br>
                    <span class="font-bold">Conclusion: Step into the Future with Nuwarex</span> <br><br>
                    Choosing Nuwarex means opting for a logistics company that propels you into the future of supply chain logistics solutions. Our expertise in freight forwarding, air cargo logistics, and third-party logistics ensures that your business is equipped to handle the demands of today and the challenges of tomorrow. Join us in revolutionizing logistics and experience the difference that Nuwarex brings to your supply chain. <br><br><br>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue';
import Navigation from '../../components/common/navigation-com.vue';
import { useHead } from '@vueuse/head';

export default {
    components: {
        Navigation,
    },

    setup() {
        onMounted(() => {
            //meta-data
            useHead({
                title: `BLOG - Revolutionizing Logistics with Nuwarex: Your Gateway to Smarter, Faster, and More Reliable Supply Chain Solutions | NUWAREX`,
                meta: [
                    {
                        name: 'description',
                        content: `In the fast-paced world of commerce, a logistics company that can deliver supply chain logistics solutions efficiently holds the key to success. Nuwarex stands out as that key, redefining the norms with its advanced freight forwarding services, air cargo logistics services, and third-party logistics. By integrating innovative practices with steadfast reliability, Nuwarex is the transformative force your business needs to excel in the global market.`
                    },
                ],
            });
        });
    }
}
</script>
<style scoped>
    
</style>