<template>
    <div>
        <div class="sized-box"></div>
        <swiper :slidesPerView="slidePerView" :loop="true" :spaceBetween="30" :pagination="{ clickable: true }" :autoplay="{ delay: 5000, disableOnInteraction: false }" :modules="modules"
            class="mySwiper">
            <swiper-slide v-for="(item, index) in cusReviews" :key="index">
                <div class="w-full p-10 bg-white transition-all duration-500 group hover:bg-nuwarex-db hover:text-white">
                    <div class="mb-5 flex justify-between items-center">
                        <div class="flex items-center">
                            <div class="w-14 h-14 mr-2 rounded-full flex-shrink-0 flex justify-center items-center overflow-hidden">
                                <img class="w-full h-full bg-cover" :src="require('../../assets/images/home/reviews/' + item.img)" alt="">
                            </div>
                            <div>
                                <p class="text-lg font-bold">{{ item.name }}</p>
                                <p class="text-sm">{{ item.company }}</p>
                            </div>
                        </div>
                        <div class="w-8 h-8">
                            <p class="text-6xl italic">"</p>
                        </div>
                    </div>
                    <p class="txt sm italic text-slate-500 transition-all duration-500 group-hover:text-white">{{ item.desc
                    }}</p>
                    <div class="w-full flex justify-center">
                        <div class="w-[150px] h-6 mt-10 flex justify-between">
                        <font-awesome-icon v-for="i in 5" :key="i"
                            class="w-6 h-6 text-nuwarex-db transition-all duration-500 group-hover:text-white"
                            :icon="['fas', 'star']" />
                    </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

export default {

    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        // let slidePerView = ref(null);
        const cusReviews = ref([
            { name: 'Christopher Nightingale', company: 'Mountbatten Hotel', img: 'christopher.webp', desc: 'As Director of Mountbatten Hotel, I am completely satisfied with Nuwarex\'s excellent logistics services. Their efficient handling and timely delivery of our imported hotel supplies has greatly improved our guest experience. A trustworthy logistic partner worthy of a 5-star rating!' },
            { name: 'Himas Niyas', company: 'Olive Timbers', img: 'himas.webp', desc: 'Olive Timbers recently had the pleasure of working with Nuwarex for our timber imports, and it was a pleasant experience. Their prompt delivery, expert handling of our delicate woods, and unrivaled customer service are all commendable. Nuwarex truly establishes the standard for logistics in Sri Lanka. 5 out of 5 stars' },
            { name: 'Azim Tharique', company: 'Scentberry', img: 'azim.webp', desc: 'I\'m happy to recommend Nuwarex. Their outstanding shipping services were critical in smoothly importing our perfumes and cosmetics. Their dependability and efficiency deserve a 5-star rating. Highly recommended for any business!' },
            { name: 'Rumesh Lahiru', company: '', img: 'user.webp', desc: 'Nuwarex provides exceptional service! As a US citizen, I recently sent a package to Sri Lanka and was completely impressed. They not only handled my shipment with care, but they also did well at navigating customs clearance and ensuring timely delivery. Highly recommended for their dependability and efficiency!' },
            { name: 'Nabeel Fuard', company: 'Gems from Ceylon', img: 'nabeel.webp', desc: 'I highly recommend Nuwarex for their amazing logistics solutions, which earned them a well-deserved five-star rating from us at Gems from Ceylon. Their professionalism and efficiency in safely and timely exporting our precious gems are unmatched in Sri Lanka. Great job team Nuwarex!' },
            { name: 'Krishan Dinuka', company: 'Krishan Auto', img: 'user.webp', desc: 'I\'m happy to have partnered with Nuwarex for our imported car parts. Their professionalism, efficiency, and extraordinary customer service have significantly improved Krishan Auto\'s operations. Nuwarex is undoubtedly a five-star logistics company in Sri Lanka!' },
            { name: 'Savith Ruwanpathirana', company: 'SR GLOBEX (PVT) LTD', img: 'savith.webp', desc: 'I am delighted to give Nuwarex a 5-star rating for their excellent shipping solutions. Their efficiency and attention to detail ensured that our valuable gemstones were exported safely and on time. Nuwarex is Highly recommended, and I am excited to work with Nuwarex again in the future.' },
        ]);
        const modules = [Pagination, Autoplay];

        const slidePerView = computed(() => {
            if (window.innerWidth >= 1024) {
                return 3;
            } else if (window.innerWidth >= 768) {
                return 2;
            } else {
                return 1;
            }
        });

        onMounted(() => {
            //set card height into same
            const cards = document.querySelectorAll(".mySwiper .swiper-slide .bg-white");
            const maxCardHeight = Math.max(...Array.from(cards).map((card) => card.clientHeight));
            cards.forEach((card) => {
                card.style.height = maxCardHeight + "px";
            });
        });

        return {
            cusReviews,
            slidePerView,
            modules,
        };
    },
};

</script>

<style scoped></style>
  