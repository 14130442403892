<template>
    <div class="bg-nuwarex-bg">
        <Navigation />
        <Banner :textAlignLeft="bannerTextLeft" :img="bannerImg">
            <template v-slot:title>About Nuwarex</template>
            <template v-slot:description>We are your reliable partner in innovative and efficient supply chain solutions.
                With years of expertise, we specialize in delivering customized logistics services, ensuring seamless and
                timely transportation of your goods. Our dedicated team is committed to excellence, transparency, and
                sustainability, striving to exceed your expectations and contribute to your success. Choose Nuwarex, choose
                unparalleled service.</template>
        </Banner>
        <div class="sized-box"></div>
        <div class="container mx-auto ">
            <div class="max-w-[500px] mx-auto">
                <h1 class="title-main text-center">Our Company Overview</h1>
                <p class="text-center">Pioneering the future of logistics. With dedication to innovation and client success,
                    we stand as your trusted global logistics partner.</p>
            </div>
            <div class="mt-10 flex flex-col lg:flex-row lg:justify-between">
                <div class="w-full pr-5 pb-5 relative flex-shrink-0 lg:mr-5 lg:w-[40%] xl:w-[60%]">
                    <img class="" src="../assets/images/about/plane.jpg" alt="">
                    <div class="w-1/3 pt-5 pl-5 absolute bottom-0 right-0 bg-nuwarex-bg">
                        <img class="w-full" src="../assets/images/about/person.jpg" alt="">
                    </div>
                </div>
                <div class="mt-10 flex flex-col items-center lg:items-end lg:mt-0">
                    <div class="flex space-x-2">
                        <button v-for="(item, index) in companyOverview" :key="index" @click="overviewSelect(index)"
                            class="w-[80px] h-[50px] mb-5 text-xs font-semibold transition-all duration-300 border border-white hover:border-nuwarex-db sm:text-base sm:w-[150px]"
                            :class="item.isSelect === true ? 'bg-nuwarex-db text-white' : 'bg-white text-nuwarex-db'">
                            {{ item.name }}
                        </button>
                    </div>
                    <transition name="fade-in-trans">
                        <p class="text-center transition-all duration-300 lg:text-right" :key="selectedOverview">{{
                            selectedOverview }}</p>
                    </transition>
                </div>
            </div>
        </div>
        <div class="sized-box"></div>
        <WorkHistoryLine />
        <!-- <div class="sized-box"></div>
        <div class="container mx-auto">
            <div class="max-w-[700px] mx-auto mb-5">
                <h1 class="title-main text-center">Meet Our Expert Team</h1>
                <p class="text-center">Meet our assembly of seasoned professionals: each an expert in their domain, collectively shaping the future of logistics. With a blend of experience and innovation, they drive our commitment to delivering exceptional service. Their knowledge is your advantage. Together, we navigate challenges and celebrate success.
                </p>
            </div>
            <div class="w-full mx-auto flex flex-col justify-between items-center gap-4 md:flex-row lg:w-[1000px]">
                <div v-for="(item, index) in teamData" :key="index" class="w-[300px] relative">
                    <div class="w-full h-[350px] md:h-[280px] lg:h-[350px]">
                        <img class="w-[100%] h-[100%] object-cover"
                            :src="require('../assets/images/about/team-profile/' + item.img)" alt="">
                    </div>
                    <div class="p-4 flex space-x-4 absolute bottom-[65px] right-0 bg-white">
                        <a v-for="(socialData, index) in item.social" :key="index" :href="socialData.link" target="_blank">
                            <div class="w-5 h-5">
                                <img class="max-w-full max-h-full" :src="require('../assets/images/icons/' + socialData.icon)" alt="">
                            </div>
                        </a>
                    </div>
                    <div class="p-5 bg-nuwarex-db">
                        <p class="font-bold text-lg text-white">{{ item.name }}</p>
                        <p class="text-sm text-white">{{ item.career }}</p>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="sized-box"></div> -->
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Navigation from "../components/common/navigation-com.vue";
import Banner from '../components/common/banner-com.vue'
import WorkHistoryLine from '../components/common/work-history-line-com.vue'
import { useHead } from '@vueuse/head';

export default {
    components: {
        Navigation,
        Banner,
        WorkHistoryLine,
    },

    setup() {
        const bannerTextLeft = ref(false);
        const bannerImg = ref('about/banners/banner-1.webp');
        const companyOverview = ref([
            { name: 'Our Vision', isSelect: true, desc: 'Nuwarex envisions a world where logistics is seamless, efficient, and sustainable. We aim to lead the industry by innovating solutions that not only meet but exceed client expectations, fostering global connections and driving commerce forward. As the heartbeat of global trade, our vision is to redefine the future of movement, making every journey, every transaction, and every connection more meaningful and impactful.' },
            { name: 'Our Mission', isSelect: false, desc: 'Nuwarex\'s mission is to redefine logistics through innovative solutions. establishing trusted partnerships and ensuring seamless global operations. We commit to elevating standards, nurturing sustainability, and empowering businessed to thive in an interconnected world. Every shipment, every client. every challenge- we\'re here, ensuring exellence in motion.' },
            { name: 'Our Motto', isSelect: false, desc: 'Connecting success. Delivering Exellence' },
        ]);
        let selectedOverview = ref(null);
        const teamData = ref([
            { name: 'Jessca Arrow', career: 'Designer', img: 'jessca-arrow.jpg', social: [{ icon: 'twit-db.png', link: '' }, { icon: 'fb-db.png', link: '/fb' }, { icon: 'insta-db.png', link: '' }] },
            { name: 'Kathleen Smith', career: 'Designer', img: 'kathleen-smith.jpg', social: [{ icon: 'in-db.png', link: '' }, { icon: 'twit-db.png', link: '' }, { icon: 'fb-db.png', link: '/fb' }, { icon: 'insta-db.png', link: '' }] },
            { name: 'Jessca Arrow', career: 'Designer', img: 'rebecca-tylor.jpg', social: [{ icon: 'fb-db.png', link: '/fb' }, { icon: 'insta-db.png', link: '' }] },
        ])

        const overviewSelect = (index) => {
            companyOverview.value = companyOverview.value.map(item => ({ ...item, isSelect: false }));
            selectedOverview.value = companyOverview.value[index].desc;
            companyOverview.value[index].isSelect = true;
        }

        onMounted(() => {
            //meta-data
            useHead({
                title: `ABOUT US | NUWAREX`,
                meta: [
                    {
                        name: 'description',
                        content: `We are your reliable partner in innovative and efficient supply chain solutions. With years of expertise, we specialize in delivering customized logistics services, ensuring seamless and timely transportation of your goods. Our dedicated team is committed to excellence, transparency, and sustainability, striving to exceed your expectations and contribute to your success. Choose Nuwarex, choose unparalleled service.`
                    },
                ],
            });
            //
            selectedOverview.value = companyOverview.value[0].desc;
        });

        return {
            bannerTextLeft,
            bannerImg,
            companyOverview,
            selectedOverview,
            teamData,
            overviewSelect,
        }
    },
}

</script>

<style scoped>
.fade-in-trans-enter-from {
    opacity: 0;
}

.fade-in-trans-enter-active {
    transition: all .5s ease;
}

.fade-in-trans-leave-active {
    transition: all 0s;
}</style>