<template>
    <div>
        <div class="w-full h-screen relative">
            <swiper class="mySwiper" :loop="true" :autoplay="{ delay: 5000, disableOnInteraction: false }"
                :modules="modules">
                <swiper-slide>
                    <div class="w-full h-screen bg-nuwarex-db">
                        <div class="w-full lg:w-1/2 h-full bg-gradient-to-r from-[#00000070] to-transparent absolute top-0 left-0"></div>
                        <div v-if="!banner1Loaded">
                            <Skeleton />
                        </div>
                        <img v-show="banner1Loaded" class="w-full h-full object-cover" src="../../assets/images/home/banners/banner-1.webp" alt="" @load="banner1loading()">
                        <div class="w-full h-full absolute top-0 left-0 flex justify-center items-center">
                            <div class="container">
                                <span class="sub-title px-2 py-1 text-sm text-white bg-black bg-opacity-30">Streamlining Global Supply
                                    Chains</span>
                                <div class="max-w-1/2 max-w-prose mt-5">
                                    <h1 class="title title-main text-white">Your Gateway to any Destination in the World</h1>
                                </div>
                                <p class="description max-w-1/4 max-w-prose text-white">At Nuwarex, we specialize in providing end-to-end logistics solutions for businesses of all sizes. With our extensive network, cutting-edge technology, and dedicated team, we are committed to optimizing your supply chain operations and ensuring seamless transportation of goods across the globe.</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="w-full h-screen bg-nuwarex-db">
                        <div class="w-full lg:w-1/2 h-full bg-gradient-to-r from-[#00000070] to-transparent absolute top-0 left-0"></div>
                        <div v-if="!banner2Loaded">
                            <Skeleton />
                        </div>
                        <img  v-show="banner2Loaded" class="w-full h-full object-cover" src="../../assets/images/home/banners/banner-2.webp" alt="" @load="banner2loading()">
                        <div class="w-full h-full absolute top-0 left-0 flex justify-center items-center">
                            <div class="container">
                                <span class="sub-title px-2 py-1 text-sm text-white bg-black bg-opacity-30">Streamlining Global Supply
                                    Chains</span>
                                <div class="max-w-1/2 max-w-prose mt-5">
                                    <h1 class="title title-main text-white">Your Gateway to any Destination in the World</h1>
                                </div>
                                <p class="description max-w-1/4 max-w-prose text-white">At Nuwarex, we specialize in providing end-to-end logistics solutions for businesses of all sizes. With our extensive network, cutting-edge technology, and dedicated team, we are committed to optimizing your supply chain operations and ensuring seamless transportation of goods across the globe.</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="w-full h-screen bg-nuwarex-db">
                        <div class="w-full lg:w-1/2 h-full bg-gradient-to-r from-[#00000070] to-transparent absolute top-0 left-0"></div>
                        <div v-if="!banner3Loaded">
                            <Skeleton />
                        </div>
                        <img v-show="banner3Loaded" class="w-full h-full object-cover" src="../../assets/images/home/banners/banner-3.webp" alt="" @load="banner3loading()">
                        <div class="w-full h-full absolute top-0 left-0 flex justify-center items-center">
                            <div class="container">
                                <span class="sub-title px-2 py-1 text-sm text-white bg-black bg-opacity-30">Streamlining Global Supply
                                    Chains</span>
                                <div class="max-w-1/2 max-w-prose mt-5">
                                    <h1 class="title title-main text-white">Your Gateway to any Destination in the World</h1>
                                </div>
                                <p class="description max-w-1/4 max-w-prose text-white">At Nuwarex, we specialize in providing end-to-end logistics solutions for businesses of all sizes. With our extensive network, cutting-edge technology, and dedicated team, we are committed to optimizing your supply chain operations and ensuring seamless transportation of goods across the globe.</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="w-full h-screen bg-nuwarex-db">
                        <div class="w-full lg:w-1/2 h-full bg-gradient-to-r from-[#00000070] to-transparent absolute top-0 left-0"></div>
                        <div v-if="!banner4Loaded">
                            <Skeleton />
                        </div>
                        <img v-show="banner4Loaded" class="w-full h-full object-cover" src="../../assets/images/home/banners/banner-4.webp" alt="" @load="banner4loading()">
                        <div class="w-full h-full absolute top-0 left-0 flex justify-center items-center">
                            <div class="container">
                                <span class="sub-title px-2 py-1 text-sm text-white bg-black bg-opacity-30">Streamlining Global Supply
                                    Chains</span>
                                <div class="max-w-1/2 max-w-prose mt-5">
                                    <h1 class="title title-main text-white">Your Gateway to any Destination in the World</h1>
                                </div>
                                <p class="description max-w-1/4 max-w-prose text-white">At Nuwarex, we specialize in providing end-to-end logistics solutions for businesses of all sizes. With our extensive network, cutting-edge technology, and dedicated team, we are committed to optimizing your supply chain operations and ensuring seamless transportation of goods across the globe.</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import Skeleton from '../common/skeleton-com.vue'

export default {
    components: {
        Swiper,
        SwiperSlide,
        Skeleton,
    },
    setup() {
        let banner1Loaded = ref(false);
        let banner2Loaded = ref(false);
        let banner3Loaded = ref(false);
        let banner4Loaded = ref(false);

        const banner1loading = () => {
            banner1Loaded.value = true;
        };

        const banner2loading = () => {
            banner2Loaded.value = true;
        };

        const banner3loading = () => {
            banner3Loaded.value = true;
        };

        const banner4loading = () => {
            banner4Loaded.value = true;
        };

        return {
            modules: [Autoplay],
            banner1Loaded,
            banner2Loaded,
            banner3Loaded,
            banner4Loaded,
            banner1loading,
            banner2loading,
            banner3loading,
            banner4loading,
        };
    },
}
</script>

<style scoped>

.sub-title{
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: .3s;
}

.title{
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: .5s;
}

.description{
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: .7s;
}

@keyframes fadeIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

</style>