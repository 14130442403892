import axios from 'axios';
import { CONSTANTS } from '../utils/constants'

export const subscribe = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/newsletter/subscribe`, data);
    return response;
};

export const contact = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/inquiry/contact`, data);
    return response;
}