<template>
    <div>
        <Navigation />
        <Banner :textAlignLeft="bannerTextLeft" :img="bannerImg">
            <template v-slot:title>Our Projects</template>
            <template v-slot:description>Explore our extensive range of logistics solutions at Nuwarex, where innovation meets efficiency. Dive into our successful projects, showcasing our commitment to timely deliveries, cost-effective strategies, and unparalleled customer service, ensuring seamless operations from start to finish. Discover the Nuwarex difference today!</template>
        </Banner>
        <div class="sized-box"></div>
        <div class="container mx-auto">
            <div class="grid gap-x-3 gap-y-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <button v-for="(item, index) in projects" :key="index" @click="this.$router.push('/')" class="w-full relative group">
                    <div class="w-full mb-5 pb-[68.25%] relative overflow-hidden">
                        <div class="w-full h-full bg-nuwarex-sec bg-opacity-50 absolute z-10 flex justify-center items-center text-lg font-bold text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">View Project</div>
                        <img class="w-full h-full bg-cover absolute" :src="require('../assets/images/project/' + item.img)" alt="">
                    </div>
                    <p class="text-xl text-center font-bold">{{ item.title }}</p>
                    <p class="text-lg text-center text-nuwarex-sec">{{ item.desc }}</p>
                </button>
            </div>
        </div>
        <div class="sized-box"></div>
        <WorkHistoryLine />
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Navigation from '../components/common/navigation-com.vue'
import Banner from '../components/common/banner-com.vue'
import WorkHistoryLine from '../components/common/work-history-line-com.vue'
import { useHead } from '@vueuse/head'

export default {
    components: {
        Navigation,
        Banner,
        WorkHistoryLine,
    },

    setup() {
        const bannerTextLeft = ref(true);
        const bannerImg = ref('project/banners/banner-1.webp');
        const projects = ref([
            { title: 'Puttalam Coal Power Plant', img: 'card-1.png', desc: 'Biggest Power Project in Sri Lanka' },
            { title: 'Puttalam Coal Power Plant', img: 'card-1.png', desc: 'Biggest Power Project in Sri Lanka' },
            { title: 'Puttalam Coal Power Plant', img: 'card-1.png', desc: 'Biggest Power Project in Sri Lanka' },
            { title: 'Puttalam Coal Power Plant', img: 'card-1.png', desc: 'Biggest Power Project in Sri Lanka' },
            { title: 'Puttalam Coal Power Plant', img: 'card-1.png', desc: 'Biggest Power Project in Sri Lanka' },
            { title: 'Puttalam Coal Power Plant', img: 'card-1.png', desc: 'Biggest Power Project in Sri Lanka' },
        ]);

        onMounted(() => {
            //meta-data
            useHead({
                title: `PROJECTS | NUWAREX`,
                meta: [
                    {
                        name: 'description',
                        content: `Explore our extensive range of logistics solutions at Nuwarex, where innovation meets efficiency. Dive into our successful projects, showcasing our commitment to timely deliveries, cost-effective strategies, and unparalleled customer service, ensuring seamless operations from start to finish. Discover the Nuwarex difference today!.`
                    },
                ],
            });
        });

        return {
            bannerTextLeft,
            bannerImg,
            projects,
        }
    }
}
</script>

<style scoped></style>