<template>
  <div>
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from './components/common/footer-com.vue'

export default{
  components: {
    Footer,
  }
}

</script>

<style>

</style>
