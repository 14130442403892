<template>
    <div>
        <loading :loading="isLoading"/>
        <div class="container mb mx-auto">
            <h1 class="title-main text-center">Get in Touch With Us</h1>
            <p class="text-center">Connect with Nuwarex today. Our dedicated team awaits to tailor solutions for your unique needs.</p>
            <div class="mt-10 flex flex-col md:flex-row md:justify-between md:items-center">
                <div class="w-full md:w-1/3">
                    <div class="mb-5 flex items-center">
                        <div class="w-14 h-14 mr-2 flex justify-center items-center rounded-full bg-nuwarex-lb flex-shrink-0">
                            <img class="max-w-[50%] max-h-[50%]" src="../../assets/images/icons/email-outline.png" alt="">
                        </div>
                        <a href="mailto:info@nuwarex.com" class="text-xl">info@nuwarex.com</a>
                    </div>
                    <div class="mb-5 flex items-center">
                        <div class="w-14 h-14 mr-2 flex justify-center items-center rounded-full bg-nuwarex-lb flex-shrink-0">
                            <img class="max-w-[50%] max-h-[50%]" src="../../assets/images/icons/phone-outline.png" alt="">
                        </div>
                        <a href="tel:+94778116668" class="text-xl">+94 77 811 6668</a>
                    </div>
                    <div class="mb-5 flex items-center">
                        <div class="w-14 h-14 mr-2 flex justify-center items-center rounded-full bg-nuwarex-lb flex-shrink-0">
                            <img class="max-w-[50%] max-h-[50%]" src="../../assets/images/icons/clock-outline.png" alt="">
                        </div>
                        <p class="text-xl">Mon - Sat 9:00 -18:00<br>Sunday Closed</p>
                    </div>
                </div>
                <div class="w-full mt-10 p-10 bg-nuwarex-db md:mt-0 md:w-2/3">
                    <input v-model="name" class="w-full h-[45px] pl-5 text-white bg-transparent border lg:w-[400px]" :class="errors.name ? 'border-red-600' : 'border-slate-600'" type="text" name="" id="" placeholder="Name*"><br>
                    <div v-if="errors.name"><span class="p-0 text-red-600 text-sm">{{ errors.name }}</span><br></div>
                    <input v-model="email" class="w-full h-[45px] mt-5 pl-5 text-white bg-transparent border lg:w-[400px]" :class="errors.email ? 'border-red-600' : 'border-slate-600'" type="text" name="" id="" placeholder="Email*"><br>
                    <div v-if="errors.email"><span class="p-0 text-red-600 text-sm">{{ errors.email }}</span><br></div>
                    <input v-model="phoneNumber" class="w-full h-[45px] mt-5 pl-5 text-white bg-transparent border lg:w-[400px]" :class="errors.phoneNumber ? 'border-red-600' : 'border-slate-600'" type="number" name="" id="" placeholder="Phone Number*"><br>
                    <div v-if="errors.phoneNumber"><span class="p-0 text-red-600 text-sm">{{ errors.phoneNumber }}</span><br></div>
                    <input v-model="city" class="w-full h-[45px] mt-5 pl-5 text-white bg-transparent border lg:w-[400px]" :class="errors.city ? 'border-red-600' : 'border-slate-600'" type="text" name="" id="" placeholder="City*"><br>
                    <div v-if="errors.city"><span class="p-0 text-red-600 text-sm">{{ errors.city }}</span><br></div>
                    <textarea v-model="message" class="w-full mt-5 pt-2 pl-5 text-white bg-transparent border" :class="errors.message ? 'border-red-600' : 'border-slate-600'" name="" id="" rows="6" placeholder="Your Message*"></textarea>
                    <div v-if="errors.message"><span class="p-0 text-red-600 text-sm">{{ errors.message }}</span><br></div>
                    <div class="flex justify-center items-center">
                        <button @click="formSubbmit()" class="px-10 mt-5 py-5 bg-white border border-white transition-all duration-300 hover:bg-nuwarex-db hover:text-white">Submit Message</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import Loading from '../common/loading-com.vue';
import { contact } from '@/services/api';
import { swal } from '../../utils/helpers';

export default {

    components: {
        Loading,
    },

    setup() {
        let isLoading = ref(false);
        let name = ref('');
        let email = ref('');
        let phoneNumber = ref('');
        let city = ref('');
        let message = ref('');
        let errors = ref({});

        const validation = () => {
            errors.value = {};

            if(name.value === '') {
                errors.value.name = 'Name is Required!';
            }

            if(email.value === '') {
                errors.value.email = 'Email is Required!'
            } else if(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
                errors.value.email = 'Invalid Email Format!';
            }

            if(phoneNumber.value === '') {
                errors.value.phoneNumber = 'Phone Number is Required!';
            }

            if(city.value === '') {
                errors.value.city = 'City is Required!';
            }

            if(message.value === '') {
                errors.value.message = 'Message is Required!';
            }
        };

        const formSubbmit = async() => {
            validation();
            
            if(Object.keys(errors.value).length === 0) {
                isLoading.value = true;
                const postData = { name: name.value, email: email.value, mobile: phoneNumber.value, city: city.value, message: message.value };
                try {
                    await contact(postData);
                    isLoading.value = false;
                    name.value = '';
                    email.value = '';
                    phoneNumber.value = '';
                    city.value = '';
                    message.value = '';
                    swal('Success', 'Your inquiry has been submitted.', 'success');
                } catch (error) {
                    swal('Failed!', 'Something went wrong. Please try again.', 'error');
                }
            }
        }

        return {
            isLoading,
            name,
            email,
            phoneNumber,
            city,
            message,
            errors,
            validation,
            formSubbmit,
        }
    }
}
</script>
<style scoped>
    
</style>