<template>
    <div>
        <loading v-model:active="isLoading" :is-full-page="fullPage" loader="bars" color="#091242" />
    </div>
</template>

<script>
import { computed } from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {

    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    },

    components: {
        Loading,
    },

    setup(props) {
        const isLoading = computed(() => {
            return props.loading;
        });

        return {
            isLoading,
        }
    }
}
</script>
<style scoped></style>