<template>
    <div class="bg-nuwarex-bg">
        <Navigation />
        <!-- <Banner /> -->
        <div class="h-20 sm:h-28" id="contact"></div>
        <Contact />
        <div class="sized-box"></div>
        <div class="container mx-auto flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div>
                <h1 class="title-main">Frequently Asked Questions</h1>
                <div v-for="(item, index) in faq" :key="index" class="mb-10">
                    <button @click="answerVisible(index)" class="w-full flex justify-between items-top">
                        <p class="mb-2 text-lg text-left font-bold">{{ item.q }}</p>
                        <font-awesome-icon class="w-5 h-5 mt-[5px] flex-shrink-0 transition-all duration-300"
                            :icon="['fa', 'angle-right']" />
                    </button>
                    <transition name="answer-trans" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave"
                        @leave="leave">
                        <div v-if="item.visible" class="overflow-hidden">
                            <p class="text-sm">{{ item.a }}</p>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="lg:ml-5 flex-shrink-0 flex justify-start items-center relative">
                <div
                    class="w-full flex flex-col items-center px-3 py-16 bg-nuwarex-db sm:px-10 sm:block sm:max-w-[350px] sm:absolute">
                    <p class="text-center mb-7 text-white font-bold sm:text-left">You Need Any Help? Get Free Consultation
                    </p>
                    <a href="tel:+94778116668">
                        <div class="flex items-center">
                            <div class="w-[50px] h-[50px] mr-2 rounded-full bg-nuwarex-lb flex justify-center items-center">
                                <img class="max-w-[40%] max-h-[40%]" src="../assets/images/icons/phone-outline.png" alt="">
                            </div>
                            <div class="text-white font-thin text-sm">
                                <p>Have Any Questions</p>
                                <p>+94 77 811 6668</p>
                            </div>
                        </div>
                    </a>
                    <button @click="makeCall()" class="mt-7 px-5 py-3 rounded-lg text-nuwarex-db bg-white">Contact
                        Us</button>
                </div>
                <div class="hidden sm:block w-[90%] ml-auto">
                    <img class="w-[100%] h-[100%] object-cover" src="../assets/images/contact/bg-contact.png" alt="">
                </div>
            </div>
        </div>
        <div class="sized-box"></div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
// import Banner from '../components/contact/banner-com.vue'
import Navigation from "../components/common/navigation-com.vue";
import Contact from '../components/common/contact-form-com.vue';
import { useHead } from '@vueuse/head';
export default {
    components: {
        Navigation,
        // Banner,
        Contact,
    },

    setup() {
        const faq = ref([
            { visible: false, q: 'What should I choose Sea or Air Shipping?', a: 'Choose sea shipping for cost-effectiveness and larger shipments without tight deadlines. Opt for air shipping if you need faster delivery, are shipping high-value items, or have smaller, time-sensitive cargo. Consider environmental impact, shipping regulations, and destination accessibility when deciding.' },
            { visible: false, q: 'Do you’ll have custom clearance services?', a: 'Yes, we handle custom clearance for your convenience, ensuring your items comply with local laws and regulations, facilitating a smooth import/export process.' },
            { visible: false, q: 'Can I import an item that I found overseas through Nuwarex?', a: 'Yes, you can import items you find overseas through Nuwarex. Ensure the item complies with import regulations and contact our customer service for assistance with shipping and customs procedures.' },
            { visible: false, q: 'How do I track my shipment?', a: 'You can track your shipment using the tracking number provided at the time of dispatch. contact our customer support for real-time updates.' },
            { visible: false, q: 'What should I do if my shipment is delayed?', a: 'If your shipment is delayed, please contact our customer service team with your tracking number. They will investigate the cause of the delay and provide you with updated delivery information and assist in resolving the issue promptly.' },
        ]);

        const answerVisible = (index) => {
            faq.value = faq.value.map(item => ({ ...item, visible: false }));
            faq.value[index].visible = true;
        };

        const beforeEnter = (el) => {
            el.style.height = '0';
        };

        const enter = (el) => {
            el.style.height = el.scrollHeight + 'px';
        };

        const beforeLeave = (el) => {
            el.style.height = el.scrollHeight + 'px';
        };

        const leave = (el) => {
            el.style.height = '0';
        };

        const makeCall = () => {
            const phoneNumber = '+94778116668';

            if (typeof window !== 'undefined') {
                window.location.href = `tel:${phoneNumber}`;
            }
        };

        onMounted(() => {
            //meta-data
            useHead({
                title: `CONTACT US | NUWAREX`,
                meta: [
                    {
                        name: 'description',
                        content: `Connect with Nuwarex today. Our dedicated team awaits to tailor solutions for your unique needs.`
                    },
                ],
            });
        });

        return {
            faq,
            answerVisible,
            beforeEnter,
            enter,
            beforeLeave,
            leave,
            makeCall,
        }
    }


}
</script>

<style scoped>
.answer-trans-enter-from,
.answer-trans-leave-to {
    max-height: 0;
}

.answer-trans-enter-active,
.answer-trans-leave-active {
    transition: all .3s ease;
}</style>