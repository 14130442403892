<template lang="">
    <div class="bg-nuwarex-bg">
        <Navigation />
        <Banner />
        <div class="w-full py-5 bg-nuwarex-nm">
            <div class="container py-2 mx-auto flex flex-col justify-between md:flex-row">
                <div class="mb-8 flex items-center md:mb-0">
                    <div class="w-12 h-12 mr-4 rounded-full bg-nuwarex-lb flex justify-center items-center">
                        <img class="max-w-[60%]" src="../assets/images/icons/clock.png" alt="time">
                    </div>
                    <div>
                        <p class="text-white text-sm">Mon - Sat 09:00 - 18:00</p>
                        <p class="text-white text-sm">Sunday Closed</p>
                    </div>
                </div>
                <div class="mb-8 flex items-center md:mb-0">
                    <div class="w-12 h-12 mr-4 rounded-full bg-nuwarex-lb flex justify-center items-center">
                        <img class="max-w-[60%]" src="../assets/images/icons/email.png" alt="time">
                    </div>
                    <div>
                        <p class="text-white text-sm">Mon - Sat 09:00 - 18:00</p>
                        <p class="text-white text-sm">Sunday Closed</p>
                    </div>
                </div>
                <div class="mb-8 flex items-center md:mb-0">
                    <div class="w-12 h-12 mr-4 rounded-full bg-nuwarex-lb flex justify-center items-center">
                        <img class="max-w-[60%]" src="../assets/images/icons/phone.png" alt="time">
                    </div>
                    <div>
                        <p class="text-white text-sm">Mon - Sat 09:00 - 18:00</p>
                        <p class="text-white text-sm">Sunday Closed</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="sized-box"></div>
        <div class="w-full">
            <div class="container mx-auto">
                <div class="grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    <div v-for="(item, index) in pointCard6" :key="index" class="w-full p-10 bg-nuwarex-db transition-all duration-500 group hover:bg-nuwarex-sec">
                        <div class="w-12 h-12 mb-5 flex justify-center items-center">
                            <img class="max-w-full max-h-full" :src="require('../assets/images/home/' + item.img)" alt="">
                        </div>
                        <p class="mb-3 text-xl text-nuwarex-sec transition-all duration-500 group-hover:text-white">{{ item.title }}</p>
                        <p class="text-white">{{ item.desc }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="sized-box"></div>
        <div class="container-bg w-full bg-cover bg-nuwarex-db">
            <div class="container py-20 mx-auto flex justify-end">
                <div class="sm:max-w-3xl">
                    <p class="text-right text-lg pb-5 text-white">Our Services</p>
                    <h1 class="title-main text-right text-white">Safe & Reliable<br>Cargo Solutions</h1>
                    <p class="text-right text-white">At Nuwarex, we specialize in providing end-to-end logistics solutions for businesses of all sizes. With our extensive network, cutting-edge technology, and dedicated team, we are committed to optimizing your supply chain operations and ensuring seamless transportation of goods across the globe.</p>
                </div>
            </div>
        </div>
        <div class="sized-box"></div>
        <div class="w-full">
            <div class="container max-w-2xl mx-auto">
                <h1 class="title-main text-center">Why Choose Our Company</h1>
                <p class="text-center">Choose Nuwarex for unrivaled logistics solutions. With a proven track record, our commitment to efficiency, transparency, and customer satisfaction sets us apart. Trust in our expertise to streamline your supply chain and elevate your business to new heights. Your success is our mission.</p>
            </div>
            <div class="sized-box"></div>
            <div class="relative hidden lg:block">
                <div class="container mx-auto flex flex-wrap relative z-10">
                    <div v-for="(item, index) in whyChoose" :key="index" class="w-1/4 px-2 text-center">
                        <div class="w-full">
                            <img class="mb-10" :src="require('../assets/images/home/' + item.img)" alt="">
                        </div>
                        <p class="mb-5 text-2xl font-bold text-white">{{ item.title }}</p>
                        <p class="text-white">{{ item.desc }}</p>
                    </div>
                </div>
                <div class="w-full h-[350px] bg-nuwarex-db absolute left-0 top-[150px]"></div>
            </div>
            <div class="relative hidden sm:block lg:hidden">
                <div class="container mx-auto flex flex-wrap relative z-10">
                    <div v-for="(item, index) in whyChoose" :key="index" class="w-1/2 px-2 mb-[120px] text-center">
                        <div class="w-full">
                            <img class="mb-10" :src="require('../assets/images/home/' + item.img)" alt="">
                        </div>
                        <p class="mb-5 text-2xl font-bold text-white">{{ item.title }}</p>
                        <p class="text-white">{{ item.desc }}</p>
                    </div>
                </div>
                <div class="w-full h-[350px] bg-nuwarex-db absolute left-0 top-[120px]"></div>
                <div class="w-full h-[350px] bg-nuwarex-db absolute left-0 top-[665px]"></div>
            </div>
            <div class="relative block sm:hidden">
                <div v-for="(item, index) in whyChoose" :key="index" class="relative mt-10">
                    <div class="container flex flex-col items-center justify-start relative z-10">
                        <div class="max-w-[500px]">
                            <img class="mb-10" :src="require('../assets/images/home/' + item.img)" alt="">
                        </div>
                        <p class="mb-5 text-2xl font-bold text-white text-center">{{ item.title }}</p>
                        <p class="mb-10 text-white text-center">{{ item.desc }}</p>
                    </div>
                    <div class="w-full h-2/3 bg-nuwarex-db absolute bottom-0 left-0"></div>
                </div>
                <!-- <div class="container mx-auto flex flex-wrap relative z-10">
                    <div v-for="(item, index) in whyChoose" :key="index" class="max-w-[430px] mx-auto px-2 mb-[120px] text-center">
                        <div class="w-full">
                            <img class="mb-10" :src="require('../assets/images/home/' + item.img)" alt="">
                        </div>
                        <p class="mb-5 text-2xl font-bold text-white">{{ item.title }}</p>
                        <p class="text-white">{{ item.desc }}</p>
                    </div>
                </div>
                <div class="w-full h-[390px] bg-nuwarex-db absolute left-0 top-[190px]"></div>
                <div class="w-full h-[390px] bg-nuwarex-db absolute left-0 top-[780px]"></div>
                <div class="w-full h-[390px] bg-nuwarex-db absolute left-0 top-[1370px]"></div>
                <div class="w-full h-[390px] bg-nuwarex-db absolute left-0 top-[1960px]"></div> -->
            </div>
        </div>
        <div class="sized-box"></div>
        <div class="w-full lg:pt-24">
            <div class="container mx-auto">
                <div class="max-w-[700px] mx-auto">
                    <h1 class="title-main text-center">What Our Customers Say</h1>
                    <p class="text-center">Our customers consistently laud Nuwarex for its exceptional service and
                        reliability. They value the peace of mind we offer, praising our responsive team
                        and timely deliveries. As our clients attest, partnering with Nuwarex means
                        choosing a logistics ally who truly understands and prioritizes your needs.</p>
                </div>
                <Reviews />
            </div>
        </div>
        <div class="sized-box"></div>
        <div class="w-full">
            <div class="container mx-auto">
                <div class="max-w-[700px] mx-auto">
                    <h1 class="title-main text-center">We Create Opportunity to Reach Potential</h1>
                    <p class="text-center">Nuwarex unlocks potential, bridging gaps to connect you with unparelleled opportunities. Your growth, our promise.</p>
                </div>
            </div>
            <div class="sized-box"></div>
            <div class="map-bg bg-cover bg-nuwarex-db">
                <div class="container py-28 mx-auto flex flex-wrap justify-center">
                    <div v-for="(item, index) in opportunity" :key="index" class="w-full mb-14 flex flex-col items-center flex-shrink-0 sm:w-1/2 md:w-1/3 lg:w-1/6 lg:mb-0">
                        <div class="w-[140px]">
                            <div class="w-[140px] h-[140px] mb-5 rounded-full flex justify-center items-center bg-nuwarex-sec">
                                <img class="max-w-[50%] max-h-[50%]" :src="require('../assets/images/home/' + item.icon)" alt="">
                            </div>
                            <p class="text-center text-white">{{ item.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sized-box"></div>
            <Contact />
        <div class="sized-box"></div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Navigation from "../components/common/navigation-com.vue";
import Banner from '../components/home/banner-com.vue';
import Reviews from '../components/home/reviews-com.vue';
import Contact from '../components/common/contact-form-com.vue';
import { useHead } from '@vueuse/head';

export default {
    components: {
        Navigation,
        Banner,
        Reviews,
        Contact,
    },

    setup() {

        const pointCard6 = ref([
            { title: 'Customs Clearance', img: 'custom-clearance.png', desc: 'Navigating complex customs regulations is our expertise. Our dedicated team ensures smooth clearance processes, minimizing delays and ensuring compliance with all relevant laws.' },
            { title: 'Warehousing and Distribution', img: 'warehousing-destribution.png', desc: 'Our strategically located warehouses offer secure and efficient storage solutions. We provide inventory management, order fulfillment, and distribution services tailored to meet your specific needs.' },
            { title: 'Transportation', img: 'transportation.png', desc: 'From trucking to air and ocean freight, we offer a wide range of transportation options to suit your requirements. Our fleet and partner carriers are equipped to handle various cargo sizes and types.' },
            { title: 'Freight Forwarding', img: 'freight-forwarding.png', desc: 'Our experienced team handles all aspects of freight forwarding, including customs clearance, documentation, and transportation. Whether it\'s air, sea, or land freight, we ensure timely and cost-effective delivery.' },
            { title: 'Cross-Docking', img: 'cross-docking.png', desc: 'Cross-docking streamlines supply chains by transferring incoming goods directly to outbound vehicles, reducing storage time and ensuring faster product delivery to your destination.' },
            { title: 'Supply Chain Consulting', img: 'supply-chain.png', desc: 'We provide expert advice on optimizing your supply chain for maximum efficiency and cost-effectiveness. Our consultants work closely with you to identify opportunities for improvement.' },
        ]);

        const whyChoose = ref([
            { title: 'Global Network', img: 'why-choose-1.jpg', desc: 'With a presence in key markets worldwide, we have the reach to manage your logistics needs anywhere in the world.' },
            { title: 'Technology-Driven Solution', img: 'why-choose-2.jpg', desc: 'Our state-of-the-art technology platform provides real-time visibility, allowing you to track your shipments and manage your inventory with ease.' },
            { title: 'Dedicated Team', img: 'why-choose-3.jpg', desc: ' Our team of experienced professionals is committed to providing exceptional service, from planning to execution.' },
            { title: 'Customized Approach', img: 'why-choose-4.jpg', desc: 'We understand that every business is unique. Our solutions are tailored to meet your specific requirements, ensuring a seamless experience.' },
        ]);

        const cusReviews = ref([
            { name: 'Kathleen Smith', company: 'Fuel Company', img: '', desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit tempora cumque aspernatur cupiditate fugit iure amet beatae delectus dolore exercitationem odio repellendus velit, distinctio, ullam nobis ad quod harum ea.' },
            { name: 'Kathleen Smith', company: 'Fuel Company', img: '', desc: 'Sit tempora cumque aspernatur cupiditate fugit iure amet beatae delectus dolore exercitationem odio repellendus velit, distinctio, ullam nobis ad quod harum ea.' },
            { name: 'Kathleen Smith', company: 'Fuel Company', img: '', desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit tempora cumque aspernatur cupiditate fugit iure amet beatae delectus dolore exercitationem odio repellendus velit, distinctio, ullam nobis ad quod harum ea.' },
        ]);

        const opportunity = ref([
            { name: 'Safe Package', icon: 'safe-package.png' },
            { name: 'Real Time Tracking', icon: 'real-time-tracking.png' },
            { name: 'In Time Delivery', icon: 'in-time-delivery.png' },
            { name: 'Multi-modal Transportation', icon: 'multi-modal-transpotation.png' },
            { name: '24/7 Customer Service', icon: 'customer-service.png' },
            { name: 'Transparent Pricing', icon: 'transparent-pricing.png' },
        ]);

        onMounted(() => {
            //meta-data
            useHead({
                title: `HOME | NUWAREX`,
                meta: [
                    {
                        name: 'description',
                        content: `Your Gateway to any Destination in the World. At Nuwarex, we specialize in providing end-to-end logistics solutions for businesses of all sizes. With our extensive network, cutting-edge technology, and dedicated team, we are committed to optimizing your supply chain operations and ensuring seamless transportation of goods across the globe.`
                    },
                ],
            });
        });

        return {
            pointCard6,
            whyChoose,
            cusReviews,
            opportunity,
        };
    },
};
</script>

<style scoped>
.container-bg {
    background-image: url('../assets/images/home/containers.png');
}

.map-bg {
    background-image: url('../assets/images/home/map.png');
}
</style>