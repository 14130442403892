<template>
    <div>
        <Navigation />
        <div class="w-full min-h-screen bg-nuwarex-bg">
            <div class="container m-auto">
                <div class="h-20 sm:h-24 md:h-28"></div>
                <div class="flex flex-col lg:flex-row">
                    <div class="w-full mt-16 flex-shrink-0 order-2 lg:order-1 lg:mt-0 lg:w-[350px] lg:mr-5">
                        <p class="text-[20px] font-bold">Category</p>
                        <hr class="my-3 border-neutral-400">
                        <div v-for="(item, index) in categories" :key="index">
                            <button class="mb-1 transition-all duration-300 hover:text-nuwarex-sec"
                                :class="item.isSelected === true ? 'text-nuwarex-lb' : 'text-black'"
                                @click="categorySelect(index)">{{ item.name }}</button><br>
                        </div>
                        <!-- <hr class="my-3 border-neutral-400">
                        <div class="sized-box"></div>
                        <p class="text-[20px] font-bold">Recent Posts</p>
                        <hr class="my-3 border-neutral-400">
                        <button v-for="(item, index) in posts" :key="index"
                            class="mb-1 transition-all duration-300 hover:text-nuwarex-sec">{{ item.title }}</button> -->
                    </div>
                    <div v-if="selectedCategoryPosts.length === 0"
                        class="w-full py-20 flex justify-center order-1 lg:order-2">
                        <p>No any related post(s)</p>
                    </div>
                    <div v-if="selectedCategoryPosts.length > 0"
                        class="w-[100%] grid gap-3 grid-cols-1 order-1 lg:order-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
                        <div v-for="(item, index) in selectedCategoryPosts" :key="index" class="w-full bg-white">
                            <div class="w-full mb-6 pb-[60%] overflow-hidden relative">
                                <img class="w-full h-100 bg-cover absolute top-0 left-0"
                                    :src="require('../assets/images/blog/' + item.img)" alt="">
                            </div>
                            <div class="h-[150px] px-2 overflow-hidden md:h-[160px] lg:h-[220px] xl:h-[200px]">
                                <p class="mb-2 font-bold">{{ item.title }}</p>
                                <p class="text-sm">{{ item.desc }}</p>
                            </div>
                            <p class="mt-4 px-2 text-xs font-bold text-slate-500">{{ item.date }}</p>
                            <button @click="$router.push(item.link)"
                                class="w-full mt-3 py-3 text-center font-bold border-t ">READ MORE</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sized-box"></div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Navigation from "../components/common/navigation-com.vue"
import { useHead } from '@vueuse/head';

export default {
    components: {
        Navigation,
    },

    setup() {

        const categories = ref([
            { name: 'All', isSelected: true },
            { name: 'Import', isSelected: false },
            { name: 'Export', isSelected: false },
            { name: 'Shipping', isSelected: false },
            { name: 'Prices', isSelected: false },
            { name: 'Legal', isSelected: false },
            { name: 'Sea', isSelected: false },
            { name: 'Air', isSelected: false },
            { name: 'Solutions', isSelected: false }
        ]);
        const posts = ref([
            { id: 1, title: 'Revolutionizing Logistics with Nuwarex', img: 'post-1-thumb.webp', link: '/blog/b1-revolutionizing-logistics-with-nuwarex', categories: ['Import', 'Export', 'Shipping', 'Prices', 'Legal', 'Sea', 'Air', 'Solutions'], desc: 'In the fast-paced world of commerce, a logistics company that can deliver supply chain logistics solutions efficiently holds the key to success. Nuwarex stands out as that key, redefining the norms with its advanced freight forwarding services, air cargo logistics services, and third-party logistics. By integrating innovative practices with steadfast reliability,', date: '2 Dec 2023' },
            { id: 2, title: 'The Green Mile', img: 'post-2-thumb.webp', link: '/blog/b2-the-green-mile', categories: ['Import', 'Export', 'Shipping', 'Prices', 'Legal', 'Sea', 'Air', 'Solutions'], desc: 'he logistics industry stands at a crossroads of progress and sustainability, where the demand for faster deliveries collides with the urgent need for environmental preservation. Navigating this \'Green Mile\', companies are increasingly investing in Sustainable Logistics Solutions, integrating Green Supply Chain Management, and focusing on Last Mile Delivery Sustainability to ensure that the road to efficiency is paved with eco-conscious decisions.', date: '10 Dec 2023' },
        ]);

        let selectedCategoryPosts = ref([]);

        const categorySelect = (index) => {
            selectedCategoryPosts.value = [];

            categories.value = categories.value.map(item => ({ ...item, isSelected: false }));
            categories.value[index].isSelected = true;

            if (categories.value[index].name === 'All') {
                selectedCategoryPosts.value = posts.value;
            } else {
                for (let post of posts.value) {
                    if (post.categories.includes(categories.value[index].name)) {
                        selectedCategoryPosts.value.push(post)
                    }
                }
            }
        };

        onMounted(() => {
            //meta-data
            useHead({
                title: `BLOG | NUWAREX`,
                meta: [
                    {
                        name: 'description',
                        content: `Explore the latest trends and innovations in logistics with NuWarex, your trusted partner in seamless supply chain solutions. Our blog delves into the intricacies of logistics services, offering valuable insights, industry updates, and expert perspectives. Stay ahead in the fast-paced world of logistics with NuWarex – where efficiency meets innovation, and your supply chain aspirations become a reality.`
                    },
                ],
            });
            //
            selectedCategoryPosts.value = posts.value;
        });

        return {
            categories,
            posts,
            selectedCategoryPosts,
            categorySelect,
        }
    }
}
</script>
<style scoped></style>