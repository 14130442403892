<template>
    <div>
        <div ref="targetDiv" class="bg-nuwarex-db">
            <div class="container mx-auto py-20 grid gap-x-3 gap-y-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
                <div v-for="(item, index) in workHistory" :key="index" class="flex justify-center items-center">
                    <p class="mr-3 text-5xl font-bold text-white">{{ item.countFrom }}</p>
                    <div class="w-4 h-4 mr-3 bg-nuwarex-sec flex-shrink-0"></div>
                    <p class="text-white">{{ item.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    setup() {
        const targetDiv = ref(null);
        let intervalId = ref(null);
        const workHistory = ref([
            { name: 'Satisfied Customers', countFrom: 0, countTo: 100 },
            { name: 'Air Shipping', countFrom: 0, countTo: 42 },
            { name: 'Sea Shipping', countFrom: 0, countTo: 35 },
            { name: 'Custom Clearance', countFrom: 0, countTo: 23 },
        ]);

        const startCount = () => {
            intervalId = setInterval(() => {
                workHistory.value[0].countFrom = workHistory.value[0].countFrom < workHistory.value[0].countTo ? workHistory.value[0].countFrom + 1 : workHistory.value[0].countFrom;
                workHistory.value[1].countFrom = workHistory.value[1].countFrom < workHistory.value[1].countTo ? workHistory.value[1].countFrom + 1 : workHistory.value[1].countFrom;
                workHistory.value[2].countFrom = workHistory.value[2].countFrom < workHistory.value[2].countTo ? workHistory.value[2].countFrom + 1 : workHistory.value[2].countFrom;
                workHistory.value[3].countFrom = workHistory.value[3].countFrom < workHistory.value[3].countTo ? workHistory.value[3].countFrom + 1 : workHistory.value[3].countFrom;

                if(workHistory.value[0].countFrom === workHistory.value[0].countTo && workHistory.value[1].countFrom === workHistory.value[1].countTo && workHistory.value[2].countFrom === workHistory.value[2].countTo && workHistory.value[3].countFrom === workHistory.value[3].countTo){
                    clearInterval(intervalId);
                    intervalId = null;
                }
            }, 20);
        };

        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // The targetDiv is now visible on the screen
                    // You can run your method here
                    startCount()
                    // Don't forget to disconnect the observer if needed
                    observer.disconnect();
                }
            });
        };

        onMounted(() => {
            const options = {
                root: null, // Use the viewport as the root
                rootMargin: '0px',
                threshold: 0.5, // Trigger when 50% of the element is visible
            };

            const observer = new IntersectionObserver(handleIntersection, options);

            if (targetDiv.value) {
                observer.observe(targetDiv.value);
            }
        });

        return {
            targetDiv,
            intervalId,
            workHistory,
        }
    }
}

</script>

<style scoped></style>