<template>
    <div class="bg-nuwarex-bg">
        <Navigation />
        <Banner :textAlignLeft="bannerTextLeft" :img="bannerImg">
            <template v-slot:title>Our Logistics Service</template>
            <template v-slot:description>Discover seamless logistics solutions with Nuwarex, your trusted partner in
                efficient transportation and supply chain management. Leveraging cutting-edge technology and a vast network,
                we ensure timely deliveries, real-time tracking, and cost-effective services tailored to meet your unique
                business needs. Experience unparalleled reliability with Nuwarex.</template>
        </Banner>
        <div class="sized-box"></div>
        <div class="container mx-auto">
            <div class="max-w-2xl mx-auto">
                <h1 class="title-main text-center">How We Work</h1>
                <p class="text-center">At Nuwarex, we streamline logistics with precision and efficiency. Our dedicated team
                    leverages cutting-edge technology to optimize routes, track shipments, and deliver your goods on time,
                    every time. Discover the future of logistics with Nuwarex.</p>
            </div>
            <div class="mt-10 flex flex-col items-center lg:flex-row">
                <div class="w-full pr-5 pb-5 relative flex-shrink-0 lg:mr-5 lg:w-[40%] xl:w-[60%]">
                    <img class="" src="../assets/images/services/building.png" alt="">
                    <div class="w-1/3 pt-5 pl-5 absolute bottom-0 right-0 bg-nuwarex-bg">
                        <img class="w-full" src="../assets/images/services/delivery.jpg" alt="">
                    </div>
                </div>
                <div class="mt-10 lg:mt-0">
                    <div v-for="(item, index) in workingProcess" :key="index" class="flex">
                        <div class="w-[50px] h-[50px] mr-5 flex-shrink-0">
                            <img class="max-w-full max-h-full" :src="require('../assets/images/services/' + item.icon)"
                                alt="">
                        </div>
                        <div class="pt-2 mb-10">
                            <p class="mb-2 text-lg font-bold">{{ item.title }}</p>
                            <p class="text-sm">{{ item.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sized-box"></div>
        <div class="flex">
            <button @click="seaShipping = true;"
                class="w-1/2 py-5 text-base font-bold transition-all duration-300 md:py-10 md:text-xl"
                :class="seaShipping === true ? 'bg-nuwarex-db text-nuwarex-sec' : 'bg-white text-nuwarex-db'">Sea
                Shipping</button>
            <button @click="seaShipping = false;"
                class="w-1/2 py-5 text-base font-bold transition-all duration-300  md:py-10 md:text-xl"
                :class="seaShipping === false ? 'bg-nuwarex-db text-nuwarex-sec' : 'bg-white text-nuwarex-db'">Air
                Shipping</button>
        </div>
        <transition name="fade-in-trans">
            <div v-if="seaShipping" class="sea-transport">
                <div class="bg-sea w-full h-full bg-cover">
                    <div class="container mx-auto py-[100px]">
                        <div class="max-w-2xl">
                            <h1 class="title-main text-white">Sea Transport Services</h1>
                            <p class="text-white">Our Sea Transport Services offer a global network of reliable shipping
                                solutions. We facilitate seamless cargo movement across oceans, ensuring timely deliveries
                                and cost-effective logistics. With Nuwarex, you can trust us to navigate the waves and
                                deliver your goods safely to their destination, every time.</p>
                        </div>
                    </div>
                </div>
                <div class="sized-box"></div>
                <div class="container mx-auto">
                    <p class="text-center">
                        When it comes to Sea Transport Services, Nuwarex stands as a beacon of reliability and excellence.
                        Our extensive fleet of vessels, equipped with state-of-the-art technology and manned by experienced
                        crews, ensures that your cargo is in safe hands from start to finish. We specialize in both domestic
                        and international sea freight, offering cost-effective and efficient solutions for a wide range of
                        industries.
                    </p><br>
                    <p class="text-center">
                        At Nuwarex, we understand the complexities of sea transport. Our dedicated team handles all aspects
                        of the process, from meticulous cargo planning and secure loading to precise scheduling and
                        real-time tracking. Whether you require container shipping, bulk cargo transport, or specialized
                        project logistics, Nuwarex is your trusted partner on the high seas. With a commitment to
                        sustainability and safety, we navigate the waves of global trade, delivering your goods to their
                        destination with unwavering dedication and precision.
                    </p>
                    <div class="sized-box"></div>
                    <div class="flex flex-col items-center justify-between md:flex-row">
                        <div class="w-1/2 mr-10 hidden flex-shrink-0 md:block">
                            <img class="max-w-full" src="../assets/images/services/sea-1.jpg" alt="">
                        </div>
                        <div>
                            <h1 class="title-main">Benifit of Sea Shipping</h1>
                            <p>Air shipping offers unparalleled speed, global accessibility, and enhanced security, ensuring
                                timely and safe delivery with efficient, real-time tracking capabilities</p>
                            <div class="mt-5 flex items-center">
                                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'arrow-right']" />
                                <p>Cost Efficiancy</p>
                            </div>
                            <div class="mt-5 flex items-center">
                                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'arrow-right']" />
                                <p>Safety</p>
                            </div>
                            <div class="mt-5 flex items-center">
                                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'arrow-right']" />
                                <p>Global Reach</p>
                            </div>
                            <div class="mt-5 flex items-center">
                                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'arrow-right']" />
                                <p>Eco Friendly</p>
                            </div>
                        </div>
                    </div>
                    <div class="sized-box"></div>
                    <div class="flex items-center justify-between">
                        <div>
                            <h1 class="title-main">How It Works</h1>
                            <p>At Nuwarex, our sea shipping process begins with meticulous planning and precise scheduling
                                to ensure a seamless transportation experience. Clients can book their shipments easily
                                through our user-friendly platform, where they provide details about the cargo, including
                                its type, size, and required delivery timeline. Our experienced team then evaluates the
                                information, determines the most efficient shipping route, and schedules the appropriate
                                vessel from our extensive fleet. We prioritize safety and efficiency, ensuring that all
                                cargo is securely loaded and properly stowed to withstand the rigors of sea travel.</p><br>
                            <p>Once the journey is underway, Nuwarex provides real-time tracking and updates, keeping
                                clients informed of their shipment’s progress. Our vessels are equipped with
                                state-of-the-art technology and navigational systems, manned by seasoned crews to navigate
                                through diverse sea conditions. Upon reaching the destination port, our on-ground team
                                ensures a swift and secure unloading process. We handle customs clearance and any necessary
                                paperwork, facilitating a smooth transition to the final delivery stage. At Nuwarex, we are
                                committed to delivering your cargo on time, every time, with unmatched precision and
                                reliability.</p>
                        </div>
                        <div class="w-1/2 ml-10 hidden flex-shrink-0 md:block">
                            <img class="max-w-full" src="../assets/images/services/sea-2.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade-in-trans">
            <div v-if="!seaShipping" class="sea-transport">
                <div class="bg-air w-full h-full bg-cover">
                    <div class="container mx-auto py-[100px]">
                        <div class="max-w-2xl">
                            <h1 class="title-main text-white">Air Transport Services</h1>
                            <p class="text-white">Skyward Freight's Air Shipping Solutions offer a global network of fast
                                and reliable:air transport options, ensuring quick, efficient, and
                                precise delivery of your shipments with professional care. : </p>
                        </div>
                    </div>
                </div>
                <div class="sized-box"></div>
                <div class="container mx-auto">
                    <p class="text-center">
                        When it comes to Air Shipping Services, Nuwarex stands out as a pinnacle of dependability and
                        excellence. Our extensive fleet of aircraft, outfitted with cutting-edge technology and operated by
                        seasoned flight crews, guarantees that your
                        cargo is securely handled from takeoff to landing. We excel in providing both domestic and
                        international air freight solutions, delivering cost-efficient and prompt services for a diverse
                        array of industries.
                    </p><br>
                    <p class="text-center">
                        At Nuwarex, we grasp the intricacies of air transport. Our devoted team manages every facet of the
                        shipping process, from meticulous cargo preparation and secure loading, to accurate scheduling and
                        real-time tracking. Whether your needs encompass container shipping, oversized cargo transport, or
                        specialized project logistics, Nuwarex is your steadfast ally in the skies. Upholding a strong
                        commitment to sustainability and safety, we soar above the challenges of global trade, ensuring your
                        goods reach their destination with steadfast dedication and precision.
                    </p>
                    <div class="sized-box"></div>
                    <div class="flex items-center justify-between">
                        <div>
                            <h1 class="title-main">Benifit of Air Shipping</h1>
                            <p>Air shipping offers unparalleled speed, global accessibility,
                                and enhanced security, ensuring timely and safe delivery
                                with efficient, real-time tracking capabilities</p>
                            <div class="mt-5 flex items-center">
                                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'arrow-right']" />
                                <p>Reliable Delivery</p>
                            </div>
                            <div class="mt-5 flex items-center">
                                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'arrow-right']" />
                                <p>Eficiancy</p>
                            </div>
                            <div class="mt-5 flex items-center">
                                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'arrow-right']" />
                                <p>Tracking</p>
                            </div>
                            <div class="mt-5 flex items-center">
                                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'arrow-right']" />
                                <p>Reduced Risk</p>
                            </div>
                        </div>
                        <div class="w-1/2 ml-10 hidden flex-shrink-0 md:block">
                            <img class="max-w-full" src="../assets/images/services/air-1.jpg" alt="">
                        </div>
                    </div>
                    <div class="sized-box"></div>
                    <div class="flex flex-col items-center justify-between md:flex-row">
                        <div class="w-1/2 mr-10 hidden flex-shrink-0 md:block">
                            <img class="max-w-full" src="../assets/images/services/air-2.jpg" alt="">
                        </div>
                        <div>
                            <h1 class="title-main">How It Works</h1>
                            <p>Nuwarex employs a streamlined and efficient process to facilitate air shipping,
                                ensuring swift and secure transportation of your goods. Upon receiving your
                                cargo, our expert team meticulously prepares and packs it, adhering to all
                                international shipping standards. The cargo is then transported to the airport,
                                where it undergoes a thorough security check before being loaded onto the
                                aircraft. Our extensive network of flights enables us to provide timely deliveries,
                                regardless of the destination.</p><br>
                            <p>Once airborne, Nuwarex’s state-of-the-art tracking system allows for real-time
                                monitoring of your shipment, offering you peace of mind and unparalleled
                                transparency. Upon arrival at the destination airport, the cargo is promptly
                                unloaded and goes through customs clearance, facilitated by our experienced
                                on-ground team. It is then prepared for the final leg of its journey, whether that
                                be via road or another means of transport, ensuring it reaches its final destination
                                promptly and securely. With Nuwarex, you can trust that your cargo is in expert
                                hands every step of the way.</p>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class="sized-box"></div>
        <WorkHistoryLine />
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Navigation from "../components/common/navigation-com.vue";
import Banner from '../components/common/banner-com.vue'
import WorkHistoryLine from '../components/common/work-history-line-com.vue'
import { useHead } from '@vueuse/head'

export default {
    components: {
        Navigation,
        Banner,
        WorkHistoryLine,
    },

    setup() {
        const bannerTextLeft = ref(false);
        const bannerImg = ref('services/banners/banner-1.webp');
        const workingProcess = ref([
            { title: 'Warehosing Services', icon: 'warehouse.png', desc: 'Efficiant storage solutions for your business needs.' },
            { title: 'Safety & Quality', icon: 'safety.png', desc: 'Service excellence, your safety, our priority, Nuwarex delivers quality logistics.' },
            { title: 'Care for Environment', icon: 'care.png', desc: 'Sustainable logistics solutions for a greener tomorrow.' },
        ]);
        let seaShipping = ref(true);

        onMounted(() => {
            //meta-data
            useHead({
                title: `SERVICES | NUWAREX`,
                meta: [
                    {
                        name: 'description',
                        content: `Discover seamless logistics solutions with Nuwarex, your trusted partner in efficient transportation and supply chain management. Leveraging cutting-edge technology and a vast network, we ensure timely deliveries, real-time tracking, and cost-effective services tailored to meet your unique business needs. Experience unparalleled reliability with Nuwarex.`
                    },
                ],
            });
        });

        return {
            bannerTextLeft,
            bannerImg,
            workingProcess,
            seaShipping,
        }
    },
}

</script>

<style scoped>
.bg-sea {
    background-image: url('../assets/images/services/sea.jpg');
}

.bg-air {
    background-image: url('../assets/images/services/air.jpg');
}

.fade-in-trans-enter-from {
    opacity: 0;
}

.fade-in-trans-enter-active {
    transition: all .5s ease;
}
</style>