<template >
    <div>
        <div class="w-full h-screen relative">
            <div v-if="bannerIsLoading">
                <Skeleton />
            </div>
            <img v-show="!bannerIsLoading" class="w-full h-full object-cover" :src="require('../../assets/images/' + img)" alt="banner" @load="bannerLoad()">
            <div class="w-full h-full absolute top-0 left-0 flex items-center">
                <div class="container mx-auto flex flex-col"
                    :class="textAlignLeft === true ? 'items-start text-left' : 'items-end text-right'">
                    <div class="max-w-1/2 max-w-prose mt-5">
                        <h1 class="title title-main text-white">
                            <slot name="title"></slot>
                        </h1>
                    </div>
                    <p class="description max-w-1/4 max-w-prose text-white">
                        <slot name="description"></slot>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import Skeleton from '../common/skeleton-com.vue'

export default {

    components: {
        Skeleton,
    },

    props: {
        textAlignLeft: Boolean,
        img: String,
    },

    setup() {
        let bannerIsLoading = ref(true);

        const bannerLoad = () => {
            bannerIsLoading.value = false;
        }

        return{
            bannerIsLoading,
            bannerLoad,
        }
    }
}
</script>
<style scoped>
.title {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: .5s;
}

.description {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: .7s;
}

@keyframes fadeIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>