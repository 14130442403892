import { createApp } from 'vue'
import App from './App.vue'
import Router from './router/index'
import VueGtag from 'vue-gtag'
import './assets/styles/tailwind.css'
import { createHead } from '@vueuse/head'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

const head = createHead();
library.add(fas);

createApp(App)
.use(VueGtag, {
    appName: 'nuwarex',
    pageTrackerScreenViewEnabled: true,
    config: {id: 'G-1WTC4LRQ7T'}
})
.use(Router)
.use(head)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
