<template lang="">
    <div class="w-full h-screen flex flex-col justify-center items-center bg-nuwarex-bg">
        <p class="text-[100px] font-bold leading-[100px] text-nuwarex-sec">404</p>
        <p class="text-[20px] text-nuwarex-nm">page not found</p>
        <router-link class="mt-5 py-5 px-10 bg-nuwarex-db text-white border border-nuwarex-db transition-all duration-200 hover:bg-nuwarex-bg hover:text-nuwarex-db" to="/">HOME</router-link>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>