<template>
    <div>
        <transition name="navi-trans">
            <div v-if="navBarVisible" class="w-full py-5 bg-nuwarex-db fixed top-0 left-0 z-20">
                <div class="container py-0 mx-auto flex justify-between items-center">
                    <router-link to="/">
                        <img class="w-24 sm:w-36" src="../../assets/images/logo/logo-white.png" alt="">
                    </router-link>
                    <div class="hidden lg:flex items-center">
                        <div class="w-24 flex justify-center">
                            <router-link to="/"
                                class="text-white transition-all duration-300 hover:text-orange-300">Home</router-link>
                        </div>
                        <div class="w-px h-5 bg-slate-500"></div>
                        <div class="w-24 flex justify-center">
                            <router-link to="/about-us"
                                class="text-white transition-all duration-300 hover:text-orange-300">About Us</router-link>
                        </div>
                        <div class="w-px h-5 bg-slate-500"></div>
                        <div class="w-24 flex justify-center">
                            <router-link to="/services"
                                class="text-white transition-all duration-300 hover:text-orange-300">Services</router-link>
                        </div>
                        <div class="w-px h-5 bg-slate-500"></div>
                        <!-- <div class="w-24 flex justify-center">
                            <router-link to="/projects" class="text-white transition-all duration-300 hover:text-orange-300">Project</router-link>
                        </div> -->
                        <div class="w-px h-5 bg-slate-500"></div>
                        <div class="w-24 flex justify-center">
                            <router-link to="/contact-us"
                                class="text-white transition-all duration-300 hover:text-orange-300">Contact</router-link>
                        </div>
                        <a href="/contact-us#contact">
                            <button
                                class="ml-5 px-4 py-3 bg-white rounded-full text-black transition-all duration-300 hover:text-white hover:bg-nuwarex-sec">Request
                                Quote</button>
                        </a>

                    </div>
                    <button @click="mobileNaviIsVisible = true;" class="h-5 flex flex-col justify-between lg:hidden">
                        <div class="w-6 h-0.5 bg-white"></div>
                        <div class="w-6 h-0.5 bg-white"></div>
                        <div class="w-6 h-0.5 bg-white"></div>
                    </button>
                </div>
            </div>
        </transition>
        <transition name="mob-navi-trans">
            <div v-if="mobileNaviIsVisible" @click="mobileNaviIsVisible = false;"
                class="w-full h-screen flex justify-end fixed top-0 right-0 z-30 lg:hidden">
                <div @click.stop class="w-full h-full p-5 flex flex-col justify-center items-center bg-nuwarex-db sm:w-4/5">
                    <div class="p-5 absolute top-0 right-0">
                        <button @click="mobileNaviIsVisible = false;" class="float-right">
                            <font-awesome-icon class="w-6 h-6 text-white" :icon="['fa', 'close']" />
                        </button>
                    </div>
                    <div class="flex flex-col items-center">
                        <router-link @click="mobileNaviIsVisible = false;" to="/" class="mb-10">
                            <img class="w-36" src="../../assets/images/logo/logo-white.png" alt="">
                        </router-link>
                        <router-link @click="mobileNaviIsVisible = false;" to="/"
                            class="mb-5 text-white transition-all duration-300 hover:text-orange-300">Home</router-link>
                        <router-link @click="mobileNaviIsVisible = false;" to="/about-us"
                            class="mb-5 text-white transition-all duration-300 hover:text-orange-300">About Us</router-link>
                        <router-link @click="mobileNaviIsVisible = false;" to="/services"
                            class="mb-5 text-white transition-all duration-300 hover:text-orange-300">Services</router-link>
                        <!-- <router-link @click="mobileNaviIsVisible = false;" to="/projects" class="mb-5 text-white transition-all duration-300 hover:text-orange-300">Project</router-link> -->
                        <router-link @click="mobileNaviIsVisible = false;" to="/contact-us"
                            class="mb-5 text-white transition-all duration-300 hover:text-orange-300">Contact</router-link>
                        <div class="w-32 h-7 mt-5 flex justify-between">
                            <a href="https://www.instagram.com/nuwarex" target="_blank" rel="noopener noreferrer">
                                <div class="w-7 h-7">
                                    <img class="max-w-full max-h-full" src="../../assets/images/icons/insta.png" alt="">
                                </div>
                            </a>
                            <a href="http://www.facebook.com/nuwarex" target="_blank" rel="noopener noreferrer">
                                <div class="w-7 h-7">
                                    <img class="max-w-full max-h-full" src="../../assets/images/icons/fb.png" alt="">
                                </div>
                            </a>
                            <a href="http://www.linkedin.com/company/nuwarex/about/" target="_blank"
                                rel="noopener noreferrer">
                                <div class="w-7 h-7">
                                    <img class="max-w-full max-h-full" src="../../assets/images/icons/in.png" alt="">
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            navBarVisible: true,
            mobileNaviIsVisible: false,
        }
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        handleScroll() {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > this.lastScrollTop) {
                this.navBarVisible = false;
            } else {
                this.navBarVisible = true;
            }
            this.lastScrollTop = st <= 0 ? 0 : st;
        },
    },
}
</script>

<style scoped>
a.router-link-exact-active {
    color: #EF660A;
}

.navi-trans-enter-from,
.navi-trans-leave-to {
    top: -90px;
    opacity: 0;
}

.navi-trans-enter-active,
.navi-trans-leave-active {
    transition: all .3s ease;
}

.mob-navi-trans-enter-from,
.mob-navi-trans-leave-to {
    right: -100%;
}

.mob-navi-trans-enter-active,
.mob-navi-trans-leave-active {
    transition: all .3s ease;
}</style>