<template>
    <div>
        <loading :loading="isLoading"/>
        <div class="w-full bg-nuwarex-nm">
            <div class="container mx-auto pt-20 text-white">
                <div class="grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
                    <div class="w-full px-5 mb-10 sm:mb-20">
                        <img class="max-w-[200px] mb-10" src="../../assets/images/logo/logo-white.png" alt="">
                        <p class="">Efficiently connecting global markets, ensuring your imports and exports arrive promptly
                            and seamlessly every time.</p>
                    </div>
                    <div class="w-full px-5 mb-10 sm:mb-20">
                        <p class="mb-5 text-xl font-bold sm:mb-10">Pages</p>
                        <div class="mb-3">
                            <router-link to="/" class="transition-all duration-300 hover:text-orange-300">Home</router-link>
                        </div>
                        <div class="mb-3">
                            <router-link to="/about-us" class="transition-all duration-300 hover:text-orange-300">About
                                Us</router-link>
                        </div>
                        <div class="mb-3">
                            <router-link to="/services"
                                class="transition-all duration-300 hover:text-orange-300">Services</router-link>
                        </div>
                        <!-- <div class="mb-3">
                            <router-link to="/projects"
                                class="transition-all duration-300 hover:text-orange-300">Project</router-link>
                        </div> -->
                        <div class="mb-3">
                            <router-link to="/contact-us"
                                class="transition-all duration-300 hover:text-orange-300">Contact</router-link>
                        </div>
                        <div class="mb-3">
                            <router-link to="/blog"
                                class="transition-all duration-300 hover:text-orange-300">Blog</router-link>
                        </div>
                        <div class="mb-3">
                            <router-link to="/legal"
                                class="transition-all duration-300 hover:text-orange-300">Legal</router-link>
                        </div>
                    </div>
                    <div class="w-full px-5 mb-10 sm:mb-20">
                        <p class="mb-5 text-xl font-bold sm:mb-10">Follow Us</p>
                        <div class="mb-3">
                            <a href="https://www.facebook.com/nuwarex" target="_blank" class="flex items-center">
                                <div class="w-5 h-5 mr-2 flex justify-center items-center">
                                    <img class="max-w-full max-h-full" src="../../assets/images/icons/fb.png" alt="">
                                </div>
                                <p>facebook</p>
                            </a>
                        </div>
                        <div class="mb-3">
                            <a href="https://www.instagram.com/nuwarex" target="_blank" class="flex items-center">
                                <div class="w-5 h-5 mr-2 flex justify-center items-center">
                                    <img class="max-w-full max-h-full" src="../../assets/images/icons/insta.png" alt="">
                                </div>
                                <p>instagram</p>
                            </a>
                        </div>
                        <div class="mb-3">
                            <a href="https://www.linkedin.com/company/nuwarex/about/" target="_blank"
                                class="flex items-center">
                                <div class="w-5 h-5 mr-2 flex justify-center items-center">
                                    <img class="max-w-full max-h-full" src="../../assets/images/icons/in.png" alt="">
                                </div>
                                <p>linkedin</p>
                            </a>
                        </div>
                    </div>
                    <div class="w-full px-5 mb-10 sm:mb-20">
                        <p class="mb-5 text-xl font-bold sm:mb-10">Subscribe</p>
                        <input v-model="email" class="w-full h-10 pl-5 bg-transparent border"
                            :class="error === '' ? 'border-slate-500' : 'border-red-600'" type="text" name="" id=""
                            placeholder="Email*">
                        <p class="text-sm text-red-600">{{ error }}</p>
                        <button @click="subscribeClick()"
                            class="px-5 py-3 mt-5 bg-white border text-black hover:text-white hover:bg-transparent transition-all duration-200">Subscribe</button>
                    </div>
                    <div class="w-full px-5 mb-20">
                        <a href="mailto:info@nuwarex.com">
                            <div class="mb-5 flex items-center">
                                <div
                                    class="w-14 h-14 mr-5 rounded-full bg-nuwarex-lb flex justify-center items-center flex-shrink-0">
                                    <img class="max-w-[50%] max-h-[50%]" src="../../assets/images/icons/email-outline.png"
                                        alt="">
                                </div>
                                <p class="text-sm">info@nuwarex.com</p>
                            </div>
                        </a>
                        <a href="tel:+94778116668">
                            <div class="flex items-center">
                                <div
                                    class="w-14 h-14 mr-5 rounded-full bg-nuwarex-lb flex justify-center items-center flex-shrink-0">
                                    <img class="max-w-[50%] max-h-[50%]" src="../../assets/images/icons/phone-outline.png"
                                        alt="">
                                </div>
                                <p class="text-sm">+94 77 811 6668</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { subscribe } from '@/services/api';
import Loading from '../common/loading-com.vue';
import { swal } from '../../utils/helpers';

export default {

    components: {
        Loading,
    },

    setup() {
        let isLoading = ref(false);
        let email = ref('');
        let error = ref('');

        const validate = () => {
            if (email.value === '') {
                error.value = 'Email is Required!'
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
                error.value = 'Invalid Email Format!';
            } else {
                error.value = '';
            }
        }

        const subscribeClick = async () => {
            validate();

            if (error.value === '') {
                isLoading.value = true;
                const postData = { email: email.value };
                try {
                    await subscribe(postData);
                    isLoading.value = false;
                    email.value = '';
                    swal('Submitted', 'You have successfully subscribed into \'Nuwarex\' newsletter', 'success');
                } catch (error) {
                    isLoading.value = false;
                    swal('Failed!', 'Something went wrong. Please try again', 'error')
                }
            }
        };

        return {
            isLoading,
            email,
            error,
            validate,
            subscribeClick,
        }
    }
}
</script>

<style scoped>
a.router-link-exact-active {
    color: #EF660A;
}
</style>