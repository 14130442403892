<template>
    <div>
        <div class="main"></div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.main {
    width: 100%;
    height: 100%;
    background: linear-gradient(110deg, #091242 8%, #00116c 18%, #091242 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    position: absolute;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
</style>